/**
 * @file 全局字典, 每次从后台缓存至前端
 */

import requests from "./requests";

class Dict {
    constructor(data) {
        const that = this;
        // 缓存dict
        this.data = data.dict;
        // emoji
        this.emoji = data.emoji;

        // 地区代码缓存
        that.area = JSON.parse(localStorage.getItem("area_code"));
        if (!that.area) {
            requests.get("/dict/areaCode")
                .then((data) => {
                    data.forEach(element => {
                        element.key = element.value;
                        if (element.children) {
                            element.children.forEach(subele => {
                                subele.key = subele.value;
                            })
                        }
                    });
                    that.area = data;
                    localStorage.setItem("area_code", JSON.stringify(data));
                })
        }
    }

    /**
     * 前端查询字典
     * @param {string} tag 桶, 比如 company, user ...
     * @param {string} key 
     */
    getValue(tag, key) {
        return this.data[tag][key];
    }

    /**
     * 前端查询 emoji
     * @param {*} tag 
     * @param {*} key 
     */
    getEmoji(tag, key) {
        return this.emoji[tag]&&this.emoji[tag][key];
    }

    getArea() {
        return this.area;
    }

    getAreaDisplay(codes) {
        if (!codes) {
            return;
        }
        function getLabelByValue(data, value) {
            for (let i = 0; i < data.length; i++) {
                const item = data[i];
                if (item.value === value) {
                    return item.label;
                }
                if (item.children) {
                    const label = getLabelByValue(item.children, value);
                    if (label) {
                        return label;
                    }
                }
            }
            return "";
        }
        const display = codes.map(value => {
            return getLabelByValue(this.area, value);
        });
        return display;
    }

}
const data = await requests.get('/dict/getDict')
const dict = new Dict(data);
export default dict;
