import React from "react"
import PropTypes from 'prop-types';
import { Tooltip } from 'antd'
import { dayjs } from '../utils/dateutil';

function TimeFormat({ time, noTime, noRelative }) {
    const dayjsObj = dayjs(time);
    const formatTime = noTime ? dayjsObj.format('YYYY/MM/DD') : dayjsObj.format('YYYY/MM/DD HH:mm');
    const relative = dayjs().from(dayjsObj);
    return <Tooltip title={formatTime} >
        {noRelative ? formatTime : relative}
    </Tooltip>
}
TimeFormat.propTypes = {
    time: PropTypes.string.isRequired,
    noTime: PropTypes.bool,
    noRelative: PropTypes.bool,
}

export { TimeFormat }