/**
 * 总框架, 菜单管理在此处
 * Pending 用户进来时不可使用主要功能
 */

import './App.css';
import { Suspense, React, useState, useEffect } from 'react';
import { Link, useRoutes, HashRouter, useLocation } from 'react-router-dom';
import { ConfigProvider, Layout, Menu, Col, Row, Space, Button, App, Result, Spin, Badge, Image } from 'antd';
import { StarOutlined, CarOutlined, FileProtectOutlined, TeamOutlined, UserOutlined, AliwangwangOutlined, PoweroffOutlined, GithubOutlined } from '@ant-design/icons';
import zhCN from 'antd/locale/zh_CN';
import routes from './routes';
import requests from './utils/requests';
import userutil from './utils/userutil';
import { MyModal } from './common/MyModal';
import { Balance } from './common/Balance';

const { Header, Footer, Content, Sider } = Layout;

function Main() {

  function Frame() {
    const headerStyle = {
      background: '#4c4cc5',
      color: 'white',
      position: 'sticky',
      top: 0,
      zIndex: 999,
      width: '100%',
      display: 'flex',
      alignItems: 'center',
      paddingLeft: '20px'
    }
    const footerStyle = {
      position: 'fixed',
      bottom: 0,
      zIndex: 999,
      width: '100%',
      display: 'flex',
      alignItems: 'center',
      padding: '10px 10px'
    }
    const siderStyle = {
      background: 'rgba(0,0,0,0)',
      overflow: 'auto',
      height: '100vh',
      position: 'fixed',
      left: 0,
      top: 0,
      bottom: 0,
      marginTop: '60px',
    }
    const contentStyle = {
      padding: '20px',
      minHeight: '100%',
      width: 'calc(100%-200px)',
    }

    const vh100 = {
      height: '100%'
    }
    const location = useLocation();
    const pathname = location.pathname;
    const isIndex = pathname === '/' || pathname.startsWith('/index');

    useEffect(() => {
      const intervalId = setInterval(() => {
        checkNewBalance()
      }, 30000);

      return () => {
        clearInterval(intervalId)
      };
    }, [])


    const [newBalance, setNewBalance] = useState(null);
    function checkNewBalance() {
      if (!requests.token) {
        return;
      }
      requests.get("/balance/newBalance")
        .then(data => {
          setNewBalance(data.balance);
        })
    }

    const userInfo = userutil.getUser();

    return <div
      style={{
        width: '100%',
        height: '100%'
      }}
    >
      <Layout style={vh100}>
        <Header style={headerStyle}>
          <Row style={{ width: '100%' }} align='middle' justify="space-between">
            <Col span={8}><Logo /></Col>
            <Col xs={0} sm={{ span: 14, offset: 2 }} justify='end'>
              <div style={{ width: '100%', display: 'flex', justifyContent: 'end' }}>
                <Space>
                  <UserOutlined /><div>{userInfo?.real_name}</div>|<div>{userInfo?.com_name || "驾驶员"}</div>|<div><Balance style={{ color: "white", textDecoration: 'underline', padding: "0" }} balance={newBalance || userInfo?.balance} userId={userInfo?.id}></Balance></div>
                </Space>
              </div>
            </Col>
          </Row>
        </Header>
        <Layout hasSider='true' style={vh100}>
          {isIndex ? null : <Sider style={siderStyle}><Navigator></Navigator></Sider>}
          <Content style={!isIndex ? { ...contentStyle, marginLeft: '200px' } : contentStyle}>
            <Suspense fallback={<Result icon={<Spin size="large" />} subTitle="加载中..." />}>
              <div style={{ background: 'white', minHeight: '100%', width: '100%', padding: '20px', paddingBottom: '100px' }}>
                {useRoutes(routes)}
              </div>
            </Suspense>
          </Content>
        </Layout>
        <Footer style={footerStyle}><CFooter></CFooter></Footer>
      </Layout>
    </div>
  }

  function Logo() {
    const logoUrl = '/static/logo/logo2.png';
    const logoText = '/static/logo/logo3_text.png';

    return <div style={{ display: 'flex', alignItems: 'center', height: '100%' }}>
      <img src={logoUrl} style={{ height: '30px' }} alt="logo" />
      <img src={logoText} style={{ height: '27px', marginLeft: '7px' }} alt="logo" />
    </div>;
  }



  function Navigator() {
    const [selectedKeys, setSelectedKeys] = useState([]);
    const location = useLocation();
    useEffect(() => {
      setSelectedKeys(location.pathname);
    }, [location.pathname])

    const handleMenuClick = ({ key }) => {
      if (key === '/logout') {
        requests.clearToken();
        window.location.href = "/";
      }
    };

    const [coopCount, setCoopCount] = useState(0);

    useEffect(() => {
      checkCoopCount();
      const intervalId = setInterval(() => {
        checkCoopCount()
      }, 60000);

      // 返回清理定时器
      return () => {
        clearInterval(intervalId)
      };
    }, [])

    function checkCoopCount() {
      if (!requests.token) {
        return;
      }
      if (!userutil.hasRole("COMPANY_ADMIN")) {
        console.log("canot load count");
        return;
      }
      requests.get("/company/coop/getMyCoop")
        .then(data => {
          let count = 0;
          data.forEach(item => {
            if (item.status_code == 'PENDING_APPROVAL' && item.from_com_id != userutil.getUser().com_id) {
              count++;
            }
          })
          setCoopCount(count);
        })
    }

    const menuItems = [
      {
        key: '/freight/waybill',
        label: <Link to={'/freight/waybill'}>我的运单</Link>,
        icon: <StarOutlined />,
      },
      {
        key: '/freight/truck',
        visible: userutil.hasCompany(),
        label: <Link to={'/freight/truck'}>我的车辆</Link>,
        icon: <CarOutlined />,
      },
      {
        key: '/coop',
        label: <Link to={'/coop'}><Badge size="small" offset={[8, -3]} count={coopCount}>商务合作</Badge></Link>,
        // 只有有公司的用户可见
        visible: userutil.hasCompany(),
        icon: <AliwangwangOutlined />,
      },
      {
        key: '/contract/manage',
        label: <Link to={'/contract/manage'}>电子合同</Link>,
        // 只有有公司的用户可见
        visible: userutil.hasRole("COMPANY_ADMIN"),
        icon: <FileProtectOutlined />,
      },
      {
        key: '/myCompany/staff',
        label: <Link to={'/myCompany/staff'}>员工管理</Link>,
        // 只有有公司的用户可见
        visible: userutil.hasRole("COMPANY_ADMIN"),
        icon: <TeamOutlined />
      },
      {
        type: 'divider',
      },
      {
        key: '/root',
        label: '平台管理',
        // 只有ROOT可见
        visible: userutil.hasRole("ROOT"),
        icon: <GithubOutlined />,
        children: [
          { key: '/root/company', label: <Link to={'/root/company'}>企业管理</Link> },
          { key: '/root/user', label: <Link to={'/root/user'}>人员管理</Link> },
          { key: '/root/esignComp', label: <Link to={'/root/esignComp'}>合同控件</Link> }
        ],
      },
      {
        key: '/logout',
        label: '退出登录',
        icon: <PoweroffOutlined style={{ color: 'red' }} />
      },
    ];

    return (
      <Menu
        onClick={handleMenuClick}
        selectedKeys={selectedKeys}
        defaultOpenKeys={userutil.isNormal() ? ['/freight', '/coop'] : ['/myCompany']}
        style={{ width: 200, height: '100%', paddingTop: '30px' }}
        mode="inline"
        items={menuItems.map((item) => {
          if (item.visible != false) {
            item.visible = undefined;
            return item;
          }
        })}
      />
    );
  }

  function CFooter() {
    const year = new Date().getFullYear();
    const aStyle = {
      margin: '0 5px',
      textDecoration: 'underline',
      textUnderlineOffset: '2px'
    }

    const [showBdDialog, setShowBdDialog] = useState(false);
    function switchBdDialog() {
      setShowBdDialog(!showBdDialog);
    }

    return <Layout>
      <Row justify='center' align='middle'>
        <Col></Col>
      </Row>
      <Row justify='center' align='middle' style={{ color: 'gray', textAlign: 'center' }}>
        © {year} 协运达 <a style={aStyle} href="https://beian.miit.gov.cn/">青ICP备2023000882号-1</a>
        <Image preview={false} style={{ width: '16px', position: 'relative', top: '-1px', marginLeft: '10px' }} src="/static/assets/gaba.png"></Image><a style={aStyle} href="http://www.beian.gov.cn/portal/registerSystemInfo?recordcode=青公网安备63010202000456号">青公网安备63010202000456号</a>
        <div style={{ marginLeft: '10px' }}>客服微信：<Button onClick={switchBdDialog} style={{ paddingLeft: '5px' }} type='link'>xthy2024</Button></div>
      </Row>
      <MyModal title="扫码添加商务微信" open={showBdDialog} onCancel={switchBdDialog}>
        <div style={{ display: 'flex', justifyContent: 'center', flexDirection: 'column', alignItems: 'center', paddingBottom: '20px' }}>
          <Image
            width={270}
            preview={false}
            src="/static/assets/bd.jpg"
          />
        </div>
      </MyModal>
    </Layout>
  }

  return (
    <ConfigProvider
      locale={zhCN}
      theme={{
        token: {
          // Seed Token，影响范围大
          // colorPrimary: '#4bb4de',
          colorPrimary: '#4c4cc5',
          borderRadius: 2,
        },
      }}
    >
      <App style={{ height: '100%' }}>
        <HashRouter>
          <Frame></Frame>
        </HashRouter>
      </App>
    </ConfigProvider>
  );
}
export default Main;
