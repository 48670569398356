import { lazy, React } from "react";
import { Navigate } from "react-router-dom"

// index
const Login = lazy(() => import("./index/login"));
const Onboarding = lazy(() => import("./index/onboarding"));
// freight
const Waybill = lazy(() => import("./freight/waybill"));
const Truck = lazy(() => import("./freight/truck"));
// coop
const Coop = lazy(() => import("./coop"));
// my company
const Staff = lazy(() => import("./myCompany/staff"));
const MyCompanyInfo = lazy(() => import("./myCompany/info"));
// root
const Company = lazy(() => import("./root/company"));
const User = lazy(() => import("./root/user"));
const EsignComp = lazy(() => import("./root/esignComp"));
// 合同
const ContractManage = lazy(() => import("./contract/manage"));

const routes = [
    {
        path: "/",
        children: [
            {
                path: "",
                skipLazyLoad: true,
                element: <Navigate to="index/login" />,
            },
            {
                path: "index/login",
                element: <Login />
            },
            {
                path: "index/onboarding",
                element: <Onboarding />
            },
        ]
    },
    {
        path: "/freight/",
        children: [
            {
                path: "waybill",
                element: <Waybill />,
            },
            {
                path: "truck",
                element: <Truck />,
            }
        ]
    },
    {
        path: "/contract/manage",
        element: <ContractManage />
    },
    {
        path: "/coop/",
        element: <Coop />
    },
    {
        path: "/myCompany/",
        children: [
            {
                path: "info",
                element: <MyCompanyInfo />
            },
            {
                path: "staff",
                element: <Staff />
            }
        ]
    },
    {
        path: "/root/",
        children: [
            {
                path: "company",
                element: <Company />
            },
            {
                path: "user",
                element: <User />
            },
            {
                path: "esignComp",
                element: <EsignComp />
            }
        ]
    },

];

export default routes;