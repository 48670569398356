/**
 * User 相关
 */

class User {
    getUser() {
        if (!this.user) {
            this.user = JSON.parse(localStorage.getItem('user_info'));
        }
        return this.user;
    }
    setUser(user) {
        localStorage.setItem('user_info', JSON.stringify(user));
        this.user = user;
    }
    hasRole(requiredRoles) {
        const user = this.getUser();
        const userRoles = user?.roles;
        if (typeof requiredRoles === 'string') {
            return userRoles?.includes(requiredRoles);
        }
        return requiredRoles.every(role => userRoles.includes(role));
    }

    hasCompany() {
        return this.getUser()?.com_id != undefined;
    }

    isNormal() {
        return this.getUser()?.status_code === 'NORMAL';
    }

    isPending() {
        return this.getUser()?.status_code === 'PENDING_APPROVAL';
    }

    isRoot() {
        return this.hasRole("ROOT");
    }
    isCarrier() {
        return this.hasRole("CARRIER");
    }
    isProducer() {
        return this.hasRole("PRODUCER");
    }
    isTrader() {
        return this.hasRole("TRADER");
    }
    isDriver() {
        return this.hasRole("DRIVER");
    }
    isAdmin() {
        return this.hasRole("COMPANY_ADMIN");
    }
}

const userutil = new User();
export default userutil;