
import React, { useState } from "react"
import PropTypes from 'prop-types';
import { Button, Space, Modal, Divider, List } from "antd";
import requests from "../utils/requests";
import { TimeFormat } from "./TimeFormat";
import dict from "../utils/dict";

function Balance({ balance, userId, style = { color: balance > 0 ? "green" : "red", textDecoration: 'underline' } }) {
    const [showRecordModal, setShowRecordModal] = useState(false);
    const [records, setRecords] = useState([]);

    function switchRecordModal() {
        if (!showRecordModal) {
            requests.get("/balance/getRecords", { id: userId })
                .then((data) => {
                    setRecords(data);
                    setShowRecordModal(true);
                })
        } else {
            setShowRecordModal(false);
        }
    }

    return <Space>
        <Button type="link" onClick={() => { switchRecordModal() }} style={style}>{balance} 元</Button>
        <Modal footer={null} destroyOnClose title="资金明细"
            width={600}
            open={showRecordModal}
            onCancel={() => { switchRecordModal() }}
        >
            <Divider></Divider>
            <List
                size="small"
                header={null}
                footer={null}
                bordered
                dataSource={records}
                style={{ maxHeight: '400px', overflow: 'auto' }}
                renderItem={(item) =>
                    <List.Item>
                        <Space>
                            <TimeFormat time={item.create_time}></TimeFormat>{dict.getValue("balance", item.event_code)}{item.remark ? item.remark : null}{item.amount}元，余额{item.balance}元
                        </Space>
                    </List.Item>
                }
            />
        </Modal>
    </Space>
}
Balance.propTypes = {
    balance: PropTypes.number.isRequired,
    userId: PropTypes.any.isRequired,
    style: PropTypes.any
}
export { Balance }