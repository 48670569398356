import React from 'react';
import PropTypes from 'prop-types';
import { Result, Button } from 'antd';

class ErrorBoundary extends React.Component {
    state = { hasError: false, error: null };

    static getDerivedStateFromError(error) {
        // 更新 state 使得下一次渲染能够显示降级 UI
        return { hasError: true, error };
    }

    componentDidCatch(error, errorInfo) {
        // 你也可以将错误日志上报给服务器
        console.error('ErrorBoundary caught an error', error, errorInfo);
    }

    resetError = () => {
        this.setState({ hasError: false, error: null });
        // 这里可以添加重置错误状态的逻辑，例如重载页面或清除错误状态
    }

    render() {
        if (this.state.hasError) {
            console.error(this.state);
            // 可以渲染任何自定义的降级 UI
            return (
                <Result
                    status="500"
                    title="发生错误，请联系客服微信：xthy2024"
                    subTitle={this.state.error?.toString()}
                    extra={
                        <Button type="primary" onClick={() => { this.resetError; window.location.reload(); }}>
                            重试
                        </Button>
                    }
                />
            );
        }

        return this.props.children;
    }
}

ErrorBoundary.propTypes = {
    children: PropTypes.node
};

export default ErrorBoundary;
