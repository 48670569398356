import axios from 'axios';
import { API_SERVER, APP_ENV } from '../config';
import { Modal, Image, Divider } from 'antd';
import React from 'react';

switch (APP_ENV) {
  case "dev":
    console.log("=== 🚧 🚧 🚧 ===");
    break;
  case "test":
    console.log("=== 🔨 🔨 🔨===");
    break;
  case "prod":
    console.log("=== 🚨 🚨 🚨 ===");
    break;
}

class Requests {
  constructor() {
    this.instance = axios.create({
      baseURL: API_SERVER,
      headers: {
        'Content-Type': 'application/json',
      },
    });

    this.token = localStorage.getItem('token');

    this.instance.interceptors.request.use(
      (config) => {
        if (this.token) {
          config.headers['Authorization'] = `Bearer ${this.token}`;
        }
        return config;
      },
      (error) => {
        return Promise.reject(error);
      }
    );
  }

  setToken(token) {
    this.token = token;
  }

  async get(endpoint, params = {}) {
    try {
      const response = await this.instance.get(endpoint, { params });
      return response.data;
    } catch (error) {
      this.handleError(error);
    }
  }

  async post(endpoint, data = {}) {
    try {
      const response = await this.instance.post(endpoint, data);
      return response.data;
    } catch (error) {
      this.handleError(error);
    }
  }

  async put(endpoint, data = {}) {
    try {
      const response = await this.instance.put(endpoint, data);
      return response.data;
    } catch (error) {
      this.handleError(error);
    }
  }

  // 添加其他 HTTP 方法的封装，如 put、delete 等

  handleError(error) {
    const httpCode = error.response && error.response.data.statusCode;
    if (httpCode === 401) {
      this.clearToken();
      window.location.href = "/";
    } else if (httpCode === 403) {
      this.clearToken();
      Modal.warning({ title: "⚠ 权限不足, 禁止访问" });
      window.location.href = "/";
    }
    else if (httpCode === 429) {
      if (error.response.data.message === 'OCR') {
        // OCR 限制为软限制, 不做提示, 只是不识别
        console.error("OCR请求受限，不再自动识别");
      } else {
        Modal.warning({ title: "请求频率过高, 请稍后再试" });
      }
    } else if (httpCode == 402) {
      Modal.warning({
        title: `余额不足`,
        content: <>
          <div>{error.response.data.message}</div>
          <div>微信扫一扫前往小程序 设置 - 充值</div>
          <div style={{ marginTop: '10px', display: 'flex', justifyContent: 'center', flexDirection: 'column', alignItems: 'center', paddingBottom: '10px' }}>
            <Image
              width={270}
              preview={false}
              src="/static/assets/mp.jpg"
            />
          </div>
        </>
      });
    } else {
      // 在此处可以添加统一的错误处理逻辑，例如记录日志、显示错误消息等
      console.error('API error: ', error);
      if (error.response) {
        throw error.response.data;
      } else {
        throw error;
      }
    }
  }

  clearToken() {
    localStorage.removeItem('token');
    localStorage.removeItem("user_info");
    this.setToken(null);
  }
}

const requests = new Requests();

export default requests;
