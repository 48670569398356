
import React from "react"
import PropTypes from 'prop-types';
import { Modal, Divider } from "antd";

function MyModal({ title, open, onCancel, children, style, maskClosable=false }) {
    return <Modal
    title={title}
    open={open}
    onCancel={onCancel}
    footer={null}
    destroyOnClose={true}
    maskClosable={maskClosable}
    style={style}
>
    <Divider></Divider>
    {children}
</Modal>
}

MyModal.propTypes = {
    onCancel: PropTypes.func.isRequired,
    title: PropTypes.string,
    open: PropTypes.bool.isRequired,
    children: PropTypes.any,
    style: PropTypes.any,
    maskClosable: PropTypes.bool
}

export { MyModal }